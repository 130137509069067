export const CARD_FOOTER_HEIGHT = 64;
export const CARD_HEADER_HEIGHT = 72;

export const enum SELECTION_TYPES {
  NUMBER,
  NUMBER_OR_DATETIME,
  LIST,
  TEXT,
  MULTILINE_TEXT,
  DICTIONARY,
  COLOR,
  NODE_PROPERTIES,
}
